export enum ThreeDsAction {
    BrowserInfo,
    MerchantTermUrl,
    SberKzChallenge,
    JysanShortChallenge,
    ConnectumChallenge,
    Way4ChallengeWithout3dsMethod,
    UnlimintChallenge,
    ChallengeWithOutPareq,
}

export const environment = {
  api: {
    host: process.env.BILLING_HOST_URL,
    methods: {
      processThreeDs: "/payment/setBrowserInfo",
      callback: "/payment/threeDsMethod",
    },
  },
  production: false,
};
